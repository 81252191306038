
import moment from 'moment';
import Vue from 'vue';

export default Vue.extend({
  props: {
    normalFileList: { type: Array },
  },
  data() {
    return {
      selection: [],
    };
  },
  methods: {
    handleSelect(val) {
      this.selection = val;
      this.$emit('onSelect', val);
    },
  },
  filters: {
    formatFileSize(val) {
      if (val) {
        if (val < 1024 * 1024) {
          return `${Math.floor(Number(val) / 1024)} KB`;
        }
        return `${Math.floor(Number(val) / 1024 / 1024)} MB`;
      }
    },
    formatTime(val) {
      if (val) {
        return moment(val).format('YYYY.MM.DD HH:mm');
      }
    },
  },
});
