
import Vue from "vue";
import SearchInput from "@/components/SearchInput.vue";
import SelectModelModal from "./select-model-modal.vue";
import moment from "moment";
import { downloadFile, removeTaskFile } from "@/api/Jobs";
import { myConfirm } from "@/utils/confirm";
import { checkModelConvertStatus } from "@/utils/check-model-convert";

export default Vue.extend({
  data() {
    return {
      publicStatus: 0,
      options: [
        { value: 0, label: "全部" },
        { value: 1, label: "公开" },
        { value: 2, label: "关闭" }
      ],
      searchValue: "",
      visible: false
    };
  },
  methods: {
    selectModel() {
      if (this.$store.state.jobManagementModule.selectedTaskModelList.length) {
        this.$message.warning("已有标准模型，请删除已有标准模型再操作");
        return;
      }
      this.$store.dispatch("jobManagementModule/getQuestionCatalogueList").then(() => {
        this.visible = true;
      });
    },
    changeStatus(e, uploadFileId) {
      this.$store.dispatch("jobManagementModule/changeStatus", { isLocked: !e, uploadFileId });
    },
    async checkModel(id) {
      const convertRes = await checkModelConvertStatus(id);
      if (convertRes) {
        let { href } = this.$router.resolve({
          path: `/model-view?id=${id}&`
        });
        window.open(href, "_blank");
      }
    },
    async downloadModel(uploadFile) {
      this.$store.dispatch("jobManagementModule/downloadQuestionModel", { uploadFile });
    },
    async deleteQuestionModel(id) {
      myConfirm(this, {
        text: "确认要删除此题库模型？",
        resolve: () => {
          this.$store.dispatch("jobManagementModule/deleteQuestionModel", { id });
        }
      });
    }
  },
  filters: {
    formatTime(val) {
      return moment(val)
        .format("YYYY-MM-DD")
        .toLocaleString();
    },
    formatSize(val) {
      if (val) {
        if (val < 1024 * 1024) {
          return `${Math.floor(Number(val) / 1024)} KB`;
        }
        return `${Math.floor(Number(val) / 1024 / 1024)} MB`;
      }
    }
  },
  computed: {
    modelTableData(): any {
      const modelList = this.$store.state.jobManagementModule.selectedTaskModelList;
      console.log("modelList", modelList);
      return modelList;
    }
  },
  components: { SearchInput, SelectModelModal }
});
