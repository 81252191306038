
import Vue from 'vue';
import * as api from '@/api';
import TaskTypeList from '@/features/collage/task-type-list/index.vue';
import TaskList from '@/features/collage/task-list/index.vue';
import CreateOrAddTaskModal, {
  CreateOrUpdateTaskFormData,
} from '@/features/collage/task-modal/index.vue';
import TopTaskTypeItem from '@/features/collage/task-type-item/index.vue';
import {
  addOrUpdateTaskType,
  createOrUpdateTask,
  deleteTask,
  deleteTaskType,
  getTasks,
  getTaskTypeList,
} from '@/api/Jobs';
import {
  Task,
  Tasks_PageListQuery,
  Tasks_SaveQuery,
  TaskType,
  TaskTypes_PageListQuery,
} from '@/features/collage/types';
import { mockTaskList, mockTaskTypesList } from '@/features/collage/const';
import TaskDetail from '@/features/collage/task-detail/index.vue';
import { myConfirm } from '@/utils/confirm';
import moment from 'moment';

enum TaskStatus {
  all,
  inProgress,
  done,
  notStart
}
export default Vue.extend({
  name: 'Jobs',
  components: {
    TaskTypeList,
    TaskList,
    CreateOrAddTaskModal,
    TopTaskTypeItem,
    TaskDetail,
  },
  data() {
    return {
      taskTypesQuery: {
        skipCount: 0,
        maxResultCount: 999,
      } as TaskTypes_PageListQuery,
      tasksQuery: { skipCount: 0, maxResultCount: 10, typeId: undefined } as Tasks_PageListQuery,
      taskTypeList: [] as TaskType[],
      taskList: [] as Task[],
      total: 0 as number,
      visible: false,
      createOrUpdateTaskModalVisible: false,
      editTask: undefined as Task | undefined,
      moveTask: undefined as Task | undefined,
      detailVisible: false,
      checkDetailSelectedTask: undefined as Task | undefined,
      searchText: '',
      taskStatus: TaskStatus.all,
      taskListLoading: false,
    };
  },
  computed: {
    sortedTaskTypeList(): any[] {
      return this.sortList(this.taskTypeList);
    },
    filterTaskList(): Task[] {
      return this.taskList
        .filter((v) => v.name.includes(this.searchText))
        .filter((v) => {
          const currentTime = moment(new Date()).valueOf();
          const endTime = moment(v.endTime).valueOf();
          const startTime = moment(v.beginTime).valueOf();
          switch (this.taskStatus) {
            case TaskStatus.all:
              return v;
            case TaskStatus.inProgress:
              return currentTime >= startTime && currentTime <= endTime;
            case TaskStatus.done:
              return currentTime > endTime;
            case TaskStatus.notStart:
              return currentTime < startTime;
            default:
              break;
          }
        });
    },
  },
  methods: {
    sortList(list: any[]) {
      return list.sort((a, b) => {
        if (a.createNickName === '超级管理员') {
          return -1;
        }
        return 0;
      });
    },
    async fetchTaskTypes() {
      const res = await getTaskTypeList(this.taskTypesQuery);
      if (res && !api.error(res)) {
        this.taskTypeList = res.data.items;
        this.total = res.data.totalCount;
        this.tasksQuery.typeId = this.$store.state.jobManagementModule.selectedTaskType?.id || res.data.items[0].id;
        this.fetchTasks();
      } else {
        this.$message.error(res.error?.message);
      }
    },
    async fetchTasks() {
      if (this.taskTypeList.length) {
        this.taskListLoading = true;
        const res = await getTasks(this.tasksQuery);
        if (res && !api.error(res)) {
          this.taskList = res.data.items;
          this.total = res.data.totalCount;
          this.taskListLoading = false;
        } else {
          this.$message.error(res.error?.message);
        }
      }
    },
    handleRowClick(id) {
      this.tasksQuery.typeId = id;
      this.fetchTasks();
    },
    handleDeleteClick(data: TaskType) {
      myConfirm(this, {
        text: `是否要删除${data.name}`,
        resolve: async () => {
          const res = await deleteTaskType({ id: data.id });
          if (res && !api.error(res)) {
            this.fetchTaskTypes();
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
          } else {
            this.$message.error(res.error?.message);
          }
        },
      });
    },
    async handleConfirm(payload: { id: string | undefined; name: string }) {
      const res = await addOrUpdateTaskType(payload);
      if (res && !api.error(res)) {
        payload.id
          ? (this.taskTypeList = this.taskTypeList.map((v) => {
            if (v.id === payload.id) {
              return { ...v, name: payload.name };
            }
            return v;
          }))
          : this.fetchTaskTypes();
        this.visible = false;
      } else {
        this.$message.error(res.error?.message);
      }
    },
    handleEditClick(data) {
      this.createOrUpdateTaskModalVisible = true;
      this.editTask = data;
    },
    async handleCreateOrCreateTaskConfirm(data: CreateOrUpdateTaskFormData) {
      const query: Tasks_SaveQuery = {
        name: data.name!,
        beginTime: data.startTimeAndEndTime?.[0],
        beginTimeStr: data.startTimeAndEndTime?.[0],
        typeId: data.typeId,
        endTime: data.startTimeAndEndTime?.[1],
        endTimeStr: data.startTimeAndEndTime?.[1],
        logo: data.logo,
        id: this.editTask?.id,
      };
      const res = await createOrUpdateTask(query);
      if (res && !api.error(res)) {
        this.$message.success(`${this.editTask ? '修改' : '新建'}作业成功`);
        this.createOrUpdateTaskModalVisible = false;
        this.fetchTasks();
      } else {
        this.$message.error(res.error?.message);
      }
    },
    handleCreateTaskBtnClick() {
      this.editTask = undefined;
      this.createOrUpdateTaskModalVisible = true;
    },

    handleTaskDeleteClick(data: Task) {
      myConfirm(this, {
        text: `是否要删除${data.name}`,
        resolve: async () => {
          const res = await deleteTask({ id: data.id });
          if (res && !api.error(res)) {
            this.fetchTasks();
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
          } else {
            this.$message.error(res.error?.message);
          }
        },
      });
    },
    handleCreateOrAddTaskModalClose() {
      this.createOrUpdateTaskModalVisible = false;
      this.editTask = undefined;
    },
    handleTaskCheckDetail(data: Task) {
      this.$store.commit('jobManagementModule/setSelectedTask', data);
      this.$store.dispatch('jobManagementModule/getModelOfTask');
      this.detailVisible = true;
      // this.$store.commit("breadcrumbList", []);
      this.checkDetailSelectedTask = data;
      console.log(data);
    },
    handleReturnClick() {
      this.detailVisible = false;
    },
  },
  async created() {
    this.$store.commit('breadcrumbList', [
      {
        name: '智能评价',
        path: 'jobs',
      },
    ]);
    this.fetchTaskTypes();
  },
});
