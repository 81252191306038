
import Vue, { PropType } from 'vue';
import * as api from '@/api';
import { correctTask } from '@/api/Jobs';
import { Commit, Task } from '../types';

export default Vue.extend({
  props: {
    list: { type: Array as PropType<Commit[]> },
    task: { type: Object as PropType<Task> },
    auditLoading: { type: Boolean, default: false },
  },
  data() {
    return {
      correctVisible: false,
      taskStatus: 0,
      taskGrade: 0,
      correctOpinion: '',
    };
  },
  computed: {
    taskName(): string {
      return this.task?.name || '';
    },
    commiter(): string {
      return this.list?.[0]?.nickName || '';
    },
    correctable(): boolean {
      return this.list.some((v) => v.commitInfo.status === 1);
    },
  },
  methods: {
    handleCorrectTask() {
      if (!this.correctable) {
        this.$message.error('作业非提交状态，无法批改');
        return;
      }
      this.correctOpinion = '';
      this.correctVisible = true;
    },
    async handleConfirm() {
      let params;
      if (this.taskStatus === -1) {
        params = {
          status: this.taskStatus,
          remark: this.correctOpinion,
          commitId: this.list[0].commitInfo.id,
          taskId: this.list[0].commitInfo.taskId,
        };
      } else {
        params = {
          status: this.taskStatus,
          remark: this.correctOpinion,
          commitId: this.list[0].commitInfo.id,
          taskId: this.list[0].commitInfo.taskId,
          mark: this.taskGrade,
        };
      }
      const res = await correctTask(params);
      if (res && !api.error(res)) {
        this.$message.success('批改成功！');
        this.$emit('refresh');
        this.correctVisible = false;
      } else {
        this.$message.error(res?.error?.message);
      }
    },
  },
});
