
import { ExportTaskUserCommits_PageByUser } from '@/api/Jobs';
import Vue, { PropType } from 'vue';
import * as api from '@/api';
import { Message } from 'element-ui';
import { Commit, Task } from '../types';

export default Vue.extend({
  props: {
    status: { type: Number },
    showCommited: { type: Boolean },
    commitList: { type: Array as PropType<Commit[]> },
    commitStatusTableSelection: { type: Array as PropType<Commit[]> },
    task: { type: Object as PropType<Task> },
  },
  data() {
    return {};
  },
  computed: {
    commitedNumber(): number {
      return this.commitList.filter((v) => Boolean(v.commitInfo)).length;
    },
    uncommitedNumber(): number {
      return this.commitList.length - this.commitedNumber;
    },
  },
  methods: {
    handleBatchOptionClick(command) {
      if (!this.commitStatusTableSelection.length) {
        Message.warning('请先选择学生');
        return;
      }
      this.$emit('onBatchOperation', command);
    },
    handleShowCommited(flag) {
      this.$emit('update:showCommited', flag);
    },
    async exportCommitExcel() {
      const res = await ExportTaskUserCommits_PageByUser({
        taskId: this.task.id,
        isCommit: true,
        maxResultCount: 999,
        skipCount: 0,
      });
      if (res && !api.error(res)) {
        const link = document.createElement('a');
        link.href = res.data.fileName;
        link.download = '';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        this.$message.error(res?.error?.message);
      }
    },
  },
});
