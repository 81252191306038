
import Vue from "vue";
import * as apiJobs from "@/api/Jobs";
import * as api from "@/api";
import moment from "moment";
import { NoticeItem } from "../types";

export default Vue.extend({
  props: {
    visible: { type: Boolean },
    taskId: { type: String }
  },
  filters: {
    formatTime(val) {
      if (val) {
        return moment(val).format("YYYY.MM.DD HH:mm:ss");
      }
    }
  },
  data() {
    return {
      noticeList: [] as NoticeItem[],
      isLoading: false,
      taskTypesQuery: {
        skipCount: 0,
        maxResultCount: 999
      },
      noticeAddText: ""
    };
  },
  watch: {
    visible() {
      if (this.visible) {
        this.getNoticeList();
      }
    }
  },
  methods: {
    async getNoticeList() {
      this.noticeList = [];
      this.isLoading = false;
      const res = await apiJobs.TaskNotices_PageList({
        ...this.taskTypesQuery,
        taskId: this.taskId
      });
      if (res && !api.error(res)) {
        this.noticeList = res.data.items;
        this.isLoading = true;
        //  loading.close();
      }
    },
    async saveNotice() {
      const res = await apiJobs.TaskNotices_Save({
        taskId: this.taskId,
        content: this.noticeAddText
      });
      if (res && !api.error(res)) {
        this.$message.success("通知添加成功");
        this.noticeAddText = "";
        this.$emit("handleCancel");
      } else {
        this.$message.error("通知添加失败，请稍后再试");
        this.$emit("handleCancel");
      }
    },
    async remove(item) {
      const res = await apiJobs.TaskNotices_Remove({ id: item.id });
      if (res && !api.error(res)) {
        this.$message.success("删除成功");
        this.getNoticeList();
      } else {
        this.$message.error("删除失败，请稍后重试");
      }
    }
  }
});
