
import { getClassList, getMemberList } from '@/api/Classes';
import Vue, { PropType } from 'vue';
import * as api from '@/api';
import { addTaskMembers, removeTaskMembers } from '@/api/Jobs';
import { isEqual } from 'lodash';
import { Message } from 'element-ui';
import { myConfirm } from '@/utils/confirm';
import { Member, Task } from '../types';
import MemberConfigureAddModal from './member-configure-add-modal.vue';

interface TreeItem {
  id: string;
  label: string;
  children?: TreeItem[];
}

export default Vue.extend({
  components: { MemberConfigureAddModal },
  props: {
    task: { type: Object as PropType<Task> },
    memberList: { type: Array as PropType<Member[]> },
    memberConfigureTableSelection: { type: Array as PropType<Member[]> },
  },
  data() {
    return {
      visible: false,
      classesAndStudents: [] as TreeItem[],
    };
  },
  methods: {
    async handleAddMemberClick() {
      const res = await getClassList({ skipCount: 0, maxResultCount: 999 });
      if (res && !api.error(res)) {
        const classList = res.data.items;
        const promises = classList.map((v) => getMemberList({
            skipCount: 0,
            maxResultCount: 999,
            classId: v.id,
            isEnabled: true,
            inClass: true,
            userTypeArr: [1],
          }));
        const memberRes: { data: { items: Member[] } }[] = (await Promise.all(promises)) as any;
        if (memberRes.every((res) => res && !api.error(res))) {
          this.classesAndStudents = classList.map((v, index) => ({
              id: v.id,
              label: `${v.name}（${memberRes[index].data.items.length}人）`,
              disabled: memberRes[index].data.items
                .map(({ userId }) => userId)
                .every((id) => this.memberList.some(({ userId }) => id === userId)),
              children: memberRes[index].data.items.map(({ userId, nickName }) => ({
                id: `${v.id}_${userId}`,
                label: nickName,
                isStudent: true,
                checked: this.memberList.some(({ userId: existedId }) => existedId === userId),
                disabled: this.memberList.some(({ userId: existedId }) => existedId === userId),
              })),
            }));
        }
      }
      this.visible = true;
    },
    batchRemove() {
      if (!this.memberConfigureTableSelection.length) {
        Message.warning('请先选择要移除的成员');
        return;
      }
      myConfirm(this, {
        text: '确认移除所勾选的成员？',
        resolve: async () => {
          const res = await removeTaskMembers({
            taskId: this.task.id,
            targetIds: [...this.memberConfigureTableSelection.map((v) => v.userId)],
          });
          if (res && !api.error(res)) {
            Message.success('移除成功');
            this.$emit('refreshMemberList');
          } else {
            Message.error(res.error.message);
          }
        },
      });
    },
    async handleConfirm(checkedStudentIds) {
      const res = await addTaskMembers({ taskId: this.task.id, targetIds: checkedStudentIds });
      if (res && !api.error(res)) {
        this.$message.success('添加成员成功！');
        this.visible = false;
        this.$emit('refreshMemberList');
      }
    },
  },
});
