
import moment from 'moment'
import type { PropType } from 'vue'
import Vue from 'vue'
import { Message } from 'element-ui'
import type { Commit } from '../types'
import { batchCommitLocked, getComponents } from '@/api/Jobs'
import * as api from '@/api'
import { getAllRule } from '@/api/BimOperationQuestion'
import { safeJsonParse } from '@/utils/safe-json-parse'
import { floatObj } from '@/utils/floatObj'

enum Status {
  保存 = 0,
  待批改 = 1,
  已驳回 = -1,
  已完成 = 2,
}
export default Vue.extend({
  props: {
    commitList: { type: Array as PropType<Commit[]> },
  },
  methods: {
    getStatus(val: number) {
      switch (val) {
        case Status.保存:
          return ''
        case Status.待批改:
          return ''
        case Status.已驳回:
          return 'danger'
        case Status.已完成:
          return 'success'
        default:
          break
      }
    },
    async dowloadPdf(commit: Commit) {
      // const { fileId } = commit.uploadFile;
      // let instancesRaw;
      // let rules;
      // const getComponentsRes: any = await getComponents({ fileId }, this.$store.state.sessionId);
      // if (getComponentsRes && !api.error(getComponentsRes)) {
      //   instancesRaw = getComponentsRes.instances;
      //   // console.log(getComponentsRes.instances);
      // }
      // const { questionId } = this.$store.state.jobManagementModule.selectedTaskModelList[0].taskFile;
      // const res = await getAllRule({ id: questionId });
      // if (res && !api.error(res)) {
      //   rules = safeJsonParse(res.data.checkRule.typeContent).componentRules;
      // }
      // console.log(rules);
      // const processNameInstances = instancesRaw
      //   .filter((v) => v.instanceType !== 'OST_Grids')
      //   .map((v) => {
      //     const name = v.name
      //       .split('_')
      //       .filter((_, index) => index > 1)
      //       .join('_');
      //     return { ...v, name };
      //   });
      // console.log(processNameInstances);
      // const finalScore = 0;
      // const arr1 = rules
      //   .map((v) => ({
      //       ...v,
      //       realInstances: processNameInstances
      //         .filter((p) => p.name.includes(v.componentName))
      //         .map((p) => ({
      //           name: p.name,
      //           id: p.id,
      //           attr: p.propertySets
      //             .map((o) => o.properties)
      //             .flat()
      //             .map(({ innerName, displayValue }) => ({ name: innerName, value: displayValue })),
      //         })),
      //     }))
      //   .filter((v) => v.realInstances.length);
      // // console.log(arr1);
      // const arr2 = arr1.map((v) => {
      //   const { realInstances } = v;
      //   const ruleAttrs = v.attributes;
      //   const compliantRuleProperties = ruleAttrs.filter((v) => {
      //     if (!realInstances.some((r) => r.attr.some((a) => a.name === v.name))) {
      //       return false;
      //     }
      //     return true;
      //   });
      //   return {
      //     ...v,
      //     attributes: compliantRuleProperties,
      //     realInstances: realInstances.map((v) => ({
      //       ...v,
      //       attr: v.attr.filter((v) => ruleAttrs.some((r) => r.name === v.name)),
      //     })),
      //   };
      // });
      // console.log(arr2);

      // const arr3 = arr2.map(({ attributes, realInstances }) => {
      //   const ranges = attributes.map((v) => {
      //     const { name } = v;
      //     const realValue = realInstances
      //       .map((v) => v.attr)
      //       .flat()
      //       .filter(({ name: attrName }) => attrName === name)
      //       .map(({ value }) => value);
      //     return { ...v, realValue };
      //   });
      //   return { attributes, realInstances, ranges };
      // });
      // console.log(arr3);

      window.open(commit?.checkReportInfo?.errorFileUrl, '__blank')
    },
    async changeAuditPublicStatus(flag, commit) {
      const res = await batchCommitLocked({
        reportLocked: !flag,
        taskCommitIds: [commit.commitInfo.id],
      })
      if (res && !api.error(res))
        this.$emit('refreshCommit')
      else
        Message.error(res.error.message)
    },
  },
  filters: {
    formatTime(val) {
      if (val)
        return moment(val).format('YYYY.MM.DD')

      return val
    },
    formatStatus(val) {
      return Status[val]
    },
  },
})
