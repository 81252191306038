
import { getModelByQuestionId, GetQuestionsResponse, JobType } from "@/api/BimOperationQuestion";
import Vue from "vue";
import Ellipsis from "../../../../components/Ellipsis.vue";
import * as api from "@/api";
import { checkModelConvertStatus } from "@/utils/check-model-convert";

export default Vue.extend({
  props: {
    visible: { type: Boolean }
  },
  data() {
    return {
      seachModelValue: "",
      selectModelId: ""
    };
  },
  computed: {
    catalogueTreeData(): {
      label: string;
      id: string;
      children?: {
        label: string;
        id: string;
      }[];
    }[] {
      const catalogueList = this.$store.state.jobManagementModule.questionCatalogueList;
      const treeData = catalogueList.map(catalogue => ({
        label: catalogue.name,
        id: catalogue.id
      }));
      return [
        {
          label: "全部",
          id: "0",
          children: treeData
        }
      ];
    },
    modelTableData(): GetQuestionsResponse {
      const questionList = this.$store.state.jobManagementModule.selectableQuestionList;
      return questionList.filter(v => v.taskName.includes(this.seachModelValue));
    }
  },
  methods: {
    handleNodeClick(data) {
      this.$store.dispatch("jobManagementModule/getQuestionList", {
        id: data.id === "0" ? undefined : data.id
      });
    },
    selectModel(id) {
      this.selectModelId = id;
    },
    async checkModel(id) {
      const res = await getModelByQuestionId({
        taskId: `${id}`,
        isTaskRule: true,
        type: JobType.questionBank,
        skipCount: 0,
        maxResultCount: 999
      });
      if (res && !api.error(res)) {
        const file = res.data.items[0].uploadFile;
        const convertRes = await checkModelConvertStatus(file.fileId);
        if (convertRes) {
          let { href } = this.$router.resolve({
            path: `/model-view?id=${file.fileId}`
          });
          window.open(href, "_blank");
        }
      }
    },
    confirm() {
      if (!this.selectModelId) {
        this.$message.error("请选择一个模型");
        return;
      }
      this.$store
        .dispatch("jobManagementModule/selectModelToTask", {
          questionId: this.selectModelId,
          taskId: this.$store.state.jobManagementModule.selectedTask.id
        })
        .then(selectModelToTaskSuccess => {
          selectModelToTaskSuccess && this.$emit("update:visible", false);
        });
    }
  },
  filters: {
    formatSize(val) {
      if (val) {
        if (val < 1024 * 1024) {
          return `${Math.floor(Number(val) / 1024)} KB`;
        }
        return `${Math.floor(Number(val) / 1024 / 1024)} MB`;
      }
    }
  },
  components: { Ellipsis }
});
