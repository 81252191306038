
import Vue, { PropType } from 'vue';
import { Rule, TaskType } from '@/features/collage/types';
import Ellipsis from '@/components/Ellipsis.vue';

export default Vue.extend({
  components: { Ellipsis },
  name: 'tasks-type-list',
  props: {
    listData: { type: Array },
    loading: { type: Boolean as PropType<boolean>, default: false },
    visible: { type: Boolean as PropType<boolean>, default: false },
  },
  data() {
    return {
      isEditMode: false,
      formData: { name: '' },
      rules: {
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
      } as Rule,
      editSelectData: undefined as TaskType | undefined,
    };
  },
  watch: {
    listData: {
      handler(val) {
        if (!this.$store.state.jobManagementModule.selectedTaskType && val) {
          this.$store.commit('jobManagementModule/setSelectedTaskType', val[0]);
        }
      },
      deep: true,
    },
  },
  methods: {
    handleRowClick(data) {
      this.$store.commit('jobManagementModule/setSelectedTaskType', data);
      this.$emit('onRowClick', data.id);
    },
    handleEditClick(data: TaskType) {
      this.isEditMode = true;
      this.editSelectData = data;
      this.formData.name = data.name;
      this.$emit('onOpen');
    },
    handleCreateClick() {
      this.isEditMode = false;
      this.editSelectData = undefined;
      this.formData.name = '';
      this.$emit('onOpen');
    },
    handleDeleteClick(data) {
      this.$emit('onDeleteClick', data);
    },
    handleConfirm() {
      if (!this.formData.name) {
        this.$message.error('名称不能为空！');
        return;
      }
      const payload = { id: this.editSelectData?.id, name: this.formData.name };
      this.$emit('onConfirm', payload);
    },
    shouldTooltip(item) {
      return (item?.name?.length || 0) >= 18;
    },
  },
});
