
import { myConfirm } from '@/utils/confirm';
import { Message } from 'element-ui';
import Vue, { PropType } from 'vue';
import * as apiJobs from '@/api/Jobs';
import { ProcessedFile } from '../types';

export default Vue.extend({
  props: {
    status: { type: Number },
    searchText: { type: String },
    taskTableSelection: { type: Array as PropType<ProcessedFile[]> },
  },
  methods: {
    handleBatchOptionClick(command) {
      if (!this.taskTableSelection.length) {
        Message.warning('请先选择作业资料');
        return;
      }
      this.$emit('onBatchOperation', command);
    },
  },
});
