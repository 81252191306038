
import Vue, { PropType } from "vue";
import { TaskType } from "@/features/collage/types";
import moment from "moment";
export default Vue.extend({
  props: {
    taskType: { type: Object as PropType<TaskType>, default: {} }
  },
  data() {
    return {
      imgSrc: "https://www.spdview.com/assets/images/document_icon_fold.png"
    };
  },
  filters: {
    formatDate(val) {
      return moment(val).format("YYYY-MM-DD");
    }
  }
});
