
import Vue, { PropType } from "vue";
import { Task, TaskType } from "@/features/collage/types";
import { ElForm } from "element-ui/types/form";
import { uploadFile } from "@/utils/upload-file";
export interface CreateOrUpdateTaskFormData
  extends Partial<Pick<Task, "name" | "logo" | "typeId">> {
  startTimeAndEndTime?: string[];
}
export default Vue.extend({
  props: {
    visible: { type: Boolean as PropType<boolean>, default: false },
    selectTask: {
      type: Object as PropType<Task | undefined>
    },
    taskTypes: { type: Array as PropType<TaskType[]>, default: [] }
  },
  computed: {
    isEdit(): boolean {
      return !!this.selectTask;
    }
  },
  data() {
    return {
      dateValue: [] as Date[],
      formData: {
        name: this.selectTask?.name,
        startTimeAndEndTime: this.selectTask
          ? [this.selectTask.beginTime, this.selectTask.endTime]
          : undefined,
        typeId: undefined
      } as CreateOrUpdateTaskFormData,
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        typeId: [{ required: true, message: "请选择分类", trigger: "change" }],
        startTimeAndEndTime: [{ required: true, message: "请选择起止时间", trigger: "change" }]
      },
      imageUrl: "",
      logo: this.selectTask?.logo || "",
      uploadableExtensions: ["png", "jpeg", "jpg"]
    };
  },
  watch: {
    selectTask: {
      handler(val: Task) {
        this.formData = {
          name: val?.name,
          startTimeAndEndTime: val ? [val.beginTime, val.endTime] : undefined,
          typeId: val?.typeId || this.$store.state.jobManagementModule.selectedTaskType?.id
        };
        this.logo = val?.logo || "";
      },
      deep: true,
      immediate: true
    },
    ["$store.state.jobManagementModule.selectedTaskType"]: {
      handler(val) {
        this.formData.typeId = val.id;
      },
      deep: true
    }
  },

  methods: {
    submitForm(formName) {
      (this.$refs[formName] as ElForm).validate(valid => {
        if (valid) {
          this.$emit("onConfirm", { ...this.formData, logo: this.logo });
          (this.$refs[formName] as ElForm).resetFields();
          this.logo = "";
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      (this.$refs[formName] as ElForm).resetFields();
      this.logo = "";
      this.$emit("onClose");
    },
    uploadModel(_file) {
      const file = _file.raw;
      const index = file.name.lastIndexOf(".");
      const type: string = file.name.slice(index + 1);
      if (!this.uploadableExtensions.some(v => v === type.toLowerCase())) {
        this.$message.warning(`暂时不支持该格式, 请上传${this.uploadableExtensions.join(" ")}文件`);
        return;
      }
      const setFile = data => {
        this.logo = process.env.VUE_APP_BORON + "/" + data.filePath;
      };
      uploadFile(file, setFile, this.$store.state.sessionId);
    },
    deleteFile() {
      this.logo = "";
    }
  }
});
