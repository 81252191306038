
import Vue, { PropType } from "vue";
import { Task } from "@/features/collage/types";
import moment from "moment";
import defaultImgSrc from "@/assets/product_thumbnail.png";
import Ellipsis from "@/components/Ellipsis.vue";

export default Vue.extend({
  components: { Ellipsis },
  props: {
    task: { type: Object as PropType<Task>, default: {} }
  },
  data() {
    return {
      imgSrc: this.task.logo || defaultImgSrc
    };
  },
  computed: {
    taskStatus(): string {
      const startTime = moment(this.task.beginTime).format("YYYY/MM/DD");
      const endTime = moment(this.task.endTime).format("YYYY/MM/DD");
      const _startTime = moment(startTime).valueOf();
      const _endTime = moment(endTime).valueOf() + 24 * 60 * 60 * 1000 - 1;
      const _currentTime = moment(new Date()).valueOf();
      if (_currentTime > _endTime) {
        return "已关闭";
      }
      if (_currentTime < _startTime) {
        return "未开始";
      }
      return "进行中";
    }
  },
  filters: {
    formatDate(val) {
      return moment(val).format("YYYY.MM.DD");
    }
  },
  watch: {
    task: {
      handler(val) {
        this.imgSrc = val.logo || defaultImgSrc;
      },
      deep: true
    }
  }
});
