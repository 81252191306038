
import Vue from "vue";
import { extensions } from "@/features/collage/const";
import { cloneDeep } from "lodash";
import { myConfirm } from "@/utils/confirm";
export default Vue.extend({
  data() {
    return {
      extensions: cloneDeep(extensions)
    };
  },
  props: {
    onlyStandard: { type: Boolean },
    visible: { type: Boolean },
    fileList: { type: Array },
    precision: { type: Number }
  },
  computed: {
    uploadLoading(): boolean {
      return !this.fileList.every((v: any) => v.percentage === "100" || v.error === true);
    }
  },
  methods: {
    handleCancel() {
      if (this.uploadLoading) {
        myConfirm(this, {
          text: "文件正在上传，取消将中断上传，确定要取消吗？",
          resolve: () => {
            this.$emit("handleCancel");
          }
        });
      } else {
        this.$emit("handleCancel");
      }
    }
  }
});
