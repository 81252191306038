
import Vue, { PropType } from 'vue'
import moment from 'moment'
import { safeJsonParse } from '@/utils/safe-json-parse'
import { ibuidingAPI } from '@/api/ibuidingAPI'
import * as api from '@/api'
import { downloadFile } from '@/api/Jobs'

export default Vue.extend({
  filters: {
    formatTime(val) {
      return moment(val).format('YYYY.MM.DD HH:mm:ss')
    },
  },
  props: {
    visible: { type: Boolean },
    item: { type: Object },
  },
  data() {
    return {
      dataFiles: [] as any,
    }
  },
  watch: {
    visible: {
      handler(val) {
        if (val === true)
          this.getDataFile()
      },
    },
  },
  methods: {
    modelCheck() {
      if (this.item.uploadFile.fileName === '资料')
        return

      this.$emit('update:visible', false)
      this.$emit('handleModleCheck')
    },
    async getDataFile() {
      const res = await ibuidingAPI.vAStudyAdminUploadFilesPageListCreate({
        taskId: this.item.commitInfo.taskId,
        type: 5,
        maxResultCount: 999,
        skipCount: 0,
      })
      if (res && !api.error(res)) {
        console.log(res.data)
        this.dataFiles = res.data.items
      }

      // const attachmentFileIds = safeJsonParse(
      //   this.item.commitInfo.attachFileIdArray,
      // ).map(String)
      // if (attachmentFileIds.length) {
      //   const res = await ibuidingAPI.vMUploadFilesUploadFilesPageListCreate({
      //     ids: attachmentFileIds,
      //   })
      //   if (res && !api.error(res))
      //     this.dataFiles = res.data.items
      // }
    },

    async downloadData(item) {
      // console.log(item);
      const res: any = await downloadFile({ fileId: item.fileId }, this.$store.state.sessionId)
      const blob = new Blob([res], { type: 'application/octet-stream' })
      const reader = new FileReader()
      reader.onload = (e) => {
        const a = document.createElement('a')
        const { fileName } = item

        a.download = decodeURIComponent(fileName!)
        a.href = e.target?.result as string
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
      reader.readAsDataURL(blob)
    },
  },
})
