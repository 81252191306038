
import type { PropType } from 'vue'
import Vue from 'vue'
import moment from 'moment'
import { Message } from 'element-ui'
import Header from './header.vue'
import TaskTable from './task-table.vue'
import CommitStatusHeader from './commit-status-header.vue'
import CommitStatusTable from './commit-status-table.vue'
import MemberConfigureHeader from './member-configure-header.vue'
import MemberConfigureTable from './member-configure-table.vue'
import UploadFileModal from './uploda-file-modal.vue'
import TaskModelViewer from './task-model-viewer.vue'
import NoticeModal from './notice-modal.vue'
import TaskModel from './task-model/index.vue'
import FileModal from './FileModal.vue'
import { batchCommitLocked, batchSynchronizationScore, correctTask } from '@/api/Jobs'
import { checkModelConvertStatus } from '@/utils/check-model-convert'
import { Base64 } from '@/utils/base64'
import TaskCommitDetail from '@/features/collage/task-commit-detail/index.vue'
import { getMemberList } from '@/api/Classes'
import { myConfirm } from '@/utils/confirm'
import type { Member } from '@/api'
import { uploadFile } from '@/utils/upload-file'
import { extensions } from '@/features/collage/const'
import {
  CommitStatus,
  CommitTableData,
  ConverStatus,
  PublicStatus,
} from '@/features/collage/types'
import type {
  Commit,
  FileData,
  ProcessedFile,
  Task,
  TaskFile,
  TaskFilesQuery,
  UploadFile,
} from '@/features/collage/types'
import * as api from '@/api'
import * as apiJobs from '@/api/Jobs'

export default Vue.extend({
  props: {
    task: {
      type: Object as PropType<Task>,
    },
    visible: { type: Boolean },
  },
  components: {
    TaskCommitDetail,
    Header,
    TaskTable,
    CommitStatusHeader,
    CommitStatusTable,
    MemberConfigureHeader,
    MemberConfigureTable,
    UploadFileModal,
    TaskModelViewer,
    NoticeModal,
    TaskModel,
    FileModal,
  },
  data() {
    return {
      activeName: 'taskFiles',
      allFileList: [] as Partial<TaskFile>[],
      uploadFileModalVisible: false,
      running: false,
      fileId: '',
      timers: [],
      fileList: [] as FileData[],
      onlyStandard: false,
      searchText: '',
      standardSearchText: '',
      publicStatus: PublicStatus.all,
      standardPublicStatus: PublicStatus.all,
      extensions,
      precision: 4,
      commitList: [] as Commit[],
      memberList: [] as Member[],
      allocationModalVisible: false,
      allocationCountModalVisible: false,
      noticeIsVisable: false,
      allocationCountValue: 50,
      countStandardValue: 1000,
      countErrorAndGrade: new Array(5).fill({ errorValue: 0, grade: 0 }).map((v, i) => ({
        errorValue: (i + 1) * 100,
        grade: 20,
      })) as {
        errorValue: number
        grade: number
      }[],
      commitDetailVisible: false,
      drawerWidth: 300,
      typeContent: '',
      rulesContent: '',
      commitStatus: CommitStatus.all,
      commitDetailList: [] as any[],
      modelViewVisible: false,
      viewFile: {} as TaskFile['uploadFile'],
      commitStatusTableSelection: [] as Commit[],
      showCommited: true,
      commit: {} as Commit,
      auditLoading: false,
      memberConfigureTableSelection: [],
      taskTableSelection: [] as ProcessedFile[],
      fileModalVisible: false,
    }
  },
  computed: {
    taskId() {
      return this.task?.id
    },
    normalFileList(): ProcessedFile[] {
      return this.allFileList
        .filter(v => !v.taskFile!.isTaskRule)
        .map(v => ({
          id: v.taskFile?.id,
          uploadFileId: v.uploadFile?.id,
          fileName: v.uploadFile?.fileName,
          fileExtention: v.uploadFile?.fileExtention,
          creationTime: v.taskFile?.creationTime,
          fileSize: v.uploadFile?.fileSize,
          isPublic: !v.uploadFile?.isLocked,
          fileConvertState: v.uploadFile?.fileConvertState,
        }))
        .filter((v) => {
          if (this.publicStatus === PublicStatus.all)
            return true

          if (this.publicStatus === PublicStatus.public)
            return v.isPublic

          return !v.isPublic
        })
        .filter(v => v.fileName?.includes(this.searchText))
    },
    standardFileList(): ProcessedFile[] {
      return this.allFileList
        .filter(v => v.taskFile!.isTaskRule)
        .map(v => ({
          id: v.taskFile?.id,
          fileName: v.uploadFile?.fileName,
          fileExtention: v.uploadFile?.fileExtention,
          creationTime: v.taskFile?.creationTime,
          fileSize: v.uploadFile?.fileSize,
          isPublic: !v.uploadFile?.isLocked,
          fileConvertState: v.uploadFile?.fileConvertState,
        }))
        .filter((v) => {
          if (this.standardPublicStatus === PublicStatus.all)
            return true

          if (this.standardPublicStatus === PublicStatus.public)
            return v.isPublic

          return !v.isPublic
        })
        .filter(v => v.fileName?.includes(this.standardSearchText))
    },
    standardFile(): Partial<TaskFile> {
      return { ...this.allFileList.find(v => v.taskFile?.isTaskRule) }
    },
    commitTableData(): Commit[] {
      if (this.showCommited) {
        return this.commitList
          .filter(v => Boolean(v.commitInfo))
          .filter(
            v => v.commitInfo.status === this.commitStatus || this.commitStatus === CommitStatus.all,
          )
      }
      return this.commitList.filter(v => !v.commitInfo)
    },
  },
  filters: {
    formatFileSize(val) {
      if (val) {
        if (val < 1024 * 1024)
          return `${Math.floor(Number(val) / 1024)} KB`

        return `${Math.floor(Number(val) / 1024 / 1024)} MB`
      }
    },
    formatTime(val) {
      if (val)
        return moment(val).format('YYYY.MM.DD hh:mm')
    },
  },
  watch: {
    task: {
      handler(val: Task) {
        if (val) {
          this.fetchTaskFiles(val.id)
          this.fetchCommits()
          this.fetchTaskMembers()
        }
      },
      deep: true,
    },
    memberList: {
      handler(val) {
        this.fetchCommits()
      },
      deep: true,
    },
    visible: {
      handler(val) {
        if (val)
          this.activeName = 'taskFiles'
      },
    },
  },
  methods: {
    async getData() {
      this.fetchTaskFiles(this.task.id)
      this.fetchCommits()
      this.fetchTaskMembers()
    },
    async fetchTaskFiles(taskId: TaskFilesQuery['taskId']) {
      const res = await apiJobs.getTaskFiles({ taskId, skipCount: 0, maxResultCount: 999 })
      if (res && !api.error(res)) {
        this.allFileList = res.data.items
        this.convertModel()
      }
      else {
        this.$message.error(res.error?.message)
      }
    },
    async setFileConvertStatus(v: Partial<TaskFile>, status: ConverStatus) {
      const uploadFileToTaskRes = await apiJobs.uploadFileToTask({
        taskFile: { ...v.taskFile! },
        uploadFile: { ...v.uploadFile, fileConvertState: status } as Partial<UploadFile>,
      })
      if (uploadFileToTaskRes && !api.error(uploadFileToTaskRes)) {
        this.allFileList = this.allFileList.map((originFile) => {
          if (originFile.uploadFile?.id === v.uploadFile?.id) {
            return {
              taskFile: { ...originFile.taskFile! },
              uploadFile: { ...originFile.uploadFile!, fileConvertState: status },
            }
          }
          return originFile
        })
      }
    },
    async pollConvertStatus(file: Partial<TaskFile>) {
      let timer
      timer = setInterval(async () => {
        const res: any = await apiJobs.getConvertProgress(
          { fileId: file.uploadFile?.fileId! },
          this.$store.state.sessionId,
        )
        if (!this.visible) {
          clearInterval(timer)
          return
        }
        // 转换进度成功，清空定时器，将file状态改为success
        if (res.status.error === 0 && res.convertStatus === 0) {
          clearInterval(timer)
          this.setFileConvertStatus(file, ConverStatus.success)
        }
        // 转换进度失败，清空定时器，将file状态改为fail
        if (res.status.error !== 0) {
          clearInterval(timer)
          this.setFileConvertStatus(file, ConverStatus.fail)
        }
      }, 4000)
    },
    async convertModel() {
      // 找到未转换成功的模型文件
      const notSuccessConvertFiles = this.allFileList.filter(
        v => (v.uploadFile?.fileExtention === '.rvt' || v.uploadFile?.fileExtention === '.dwg')
          && v.uploadFile.fileConvertState !== ConverStatus.success,
      )
      notSuccessConvertFiles.forEach(async (v) => {
        await apiJobs
          .getConvertProgress({ fileId: v.uploadFile!.fileId }, this.$store.state.sessionId)
          .then(async (res: any) => {
            // 首次查询文件进度，还未开始转换
            if (res.status.error === 20012) {
              // 发起转换
              const convertRes: any = await apiJobs.convertModel(
                { fileId: v.uploadFile?.fileId!, tessLevel: this.precision },
                this.$store.state.sessionId,
              )
              // 转换发起成功
              if (convertRes.status.error === 0) {
                // 修改file状态为inProgress
                this.setFileConvertStatus(v, ConverStatus.inProgress)
                // 轮询转换进度
                this.pollConvertStatus(v)
              }
              return
            }
            //  首次查询文件进度，已经转换成功
            if (res.status.error === 0 && res.convertStatus === 0) {
              // 将file状态改为success
              if (v.uploadFile?.fileConvertState !== ConverStatus.success)
                this.setFileConvertStatus(v, ConverStatus.success)

              return
            }
            //  首次查询文件进度，文件正在转换
            if (res.convertStatus === 1) {
              this.pollConvertStatus(v)
              return
            }
            //  首次查询文件进度，文件转换失败
            if (res.status.error !== 0) {
              // 将file状态改为fail
              this.setFileConvertStatus(v, ConverStatus.fail)
            }
          })
      })
    },
    async fetchCommits() {
      const res = await apiJobs.fetchCommits({
        taskId: this.task.id,
        skipCount: 0,
        maxResultCount: 999,
      })
      if (res && !api.error(res))
        this.commitList = res.data.items
      else
        this.$message.error(res.error?.message)
    },
    async handleCheck(item) {
      const file = this.allFileList.find(v => v.taskFile?.id === item.id)!
      if (file.uploadFile && ['.rvt', '.dwg', '.ifc'].includes(file.uploadFile?.fileExtention!)) {
        const convertRes = await checkModelConvertStatus(file.uploadFile.fileId)
        if (convertRes) {
          this.viewFile = file.uploadFile!
          this.commit = {} as Commit
          // this.modelViewVisible = true;
          const { href } = this.$router.resolve({
            path: `/model-view?id=${file.uploadFile.fileId}&draw=${file.uploadFile?.fileExtention === '.dwg' ? 'true' : 'false'
              }&rightmenu=${file.uploadFile?.fileExtention === '.rvt' ? 'true' : 'false'}`,
          })
          window.open(href, '_blank')
        }
      }
      else {
        const isImg = ['.jpeg', '.jpg', '.png'].includes(file.uploadFile?.fileExtention!)
        window.open(
          `https://fileview.spdspd.com/onlinePreview?url=${encodeURIComponent(
            Base64.encode(
              `${`${process.env.VUE_APP_FILEPREVIEW
              }/`}${file.uploadFile?.fileUrl
              }?authorization=Bearer%20${this.$store.state.sessionId
              }&fullfilename=${file.uploadFile?.fileName}`,
            ),
          )}&watermarkTxt=${this.$store.state.userInfo.nickName}`,
        )
      }
    },
    handleTaskTableSelect(val) {
      this.taskTableSelection = val
    },
    async handlePublicChange(e, id) {
      const currentFile = this.allFileList.find(v => v.taskFile?.id === id)
      const uploadFileToTaskRes = await apiJobs.uploadFileToTask({
        taskFile: { ...currentFile!.taskFile! },
        uploadFile: { ...currentFile!.uploadFile, isLocked: !e } as any,
      })
      if (uploadFileToTaskRes && !api.error(uploadFileToTaskRes)) {
        this.allFileList = this.allFileList.map((v) => {
          if (v.taskFile!.id === id) {
            return {
              taskFile: { ...currentFile!.taskFile! },
              uploadFile: { ...currentFile!.uploadFile, isLocked: !e } as any,
            }
          }
          return v
        })
      }
    },
    async handleFileUploadChange(_file) {
      if (this.onlyStandard && this.fileList.length > 0) {
        this.$message.error('仅能上传一个标准模型')
        return
      }
      const file = _file.raw
      const index = file.name.lastIndexOf('.')
      const type: string = file.name.slice(index)
      const uploadableExtensions = this.onlyStandard ? ['.rvt'] : this.extensions
      if (!uploadableExtensions.some(v => v === type.toLowerCase())) {
        this.$message.warning(`暂时不支持该格式, 请上传${uploadableExtensions.join(' ')}文件`)
        return
      }
      this.fileList = [...this.fileList, _file]
      const setFile = (data) => {
        const {
          percentage, filePath, fileId, error,
        } = data
        this.fileList = this.fileList.map((v) => {
          if (v.uid === _file.uid) {
            return {
              ...v,
              percentage: percentage ?? v.percentage,
              filePath: filePath ?? v.filePath,
              fileId: fileId ?? v.fileId,
              error: error ?? v.error,
            }
          }
          return v
        })
      }
      uploadFile(file, setFile, this.$store.state.sessionId)
    },
    handleUploadFileDelete(uid) {
      this.fileList = this.fileList.filter(v => v.uid !== uid)
    },
    handleCancel() {
      this.fileList = []
      this.uploadFileModalVisible = false
    },
    close() {
      this.noticeIsVisable = false
    },
    handleFileUploadClick() {
      this.onlyStandard = false
      this.uploadFileModalVisible = true
    },
    handleBatchOperation(command) {
      switch (command) {
        case 0: // 删除
          myConfirm(this, {
            text: '确认要删除所选资料？',
            resolve: async () => {
              const res = await Promise.all(
                this.taskTableSelection.map(v => apiJobs.removeTaskFile({ id: v.id! })),
              )
              if (res.every(v => Boolean(v) && !api.error(v))) {
                Message.success('删除资料成功')
                this.fetchTaskFiles(this.task.id)
              }
              else {
                res.forEach(v => Message.error((v as any).error.message))
              }
            },
          })
          break
        default:
          myConfirm(this, {
            text: `确认要${command === 1 ? '公开' : '私密'}所选资料？`,
            resolve: async () => {
              const res = await apiJobs.batchUploadFileLocked({
                isLocked: command !== 1,
                uploadFileIds: this.taskTableSelection.map(v => v.uploadFileId!),
              })
              if (res && !api.error(res)) {
                Message.success(`${command === 1 ? '公开' : '私密'}资料成功`)
                this.fetchTaskFiles(this.task.id)
              }
              else {
                Message.error(res.error.message)
              }
            },
          })
          break
      }
    },
    async handleUploadFileConfirm() {
      const promises: Promise<any>[] = this.fileList
        .filter(v => !v.error)
        .map(v => apiJobs.uploadFileToTask({
          taskFile: {
            taskId: this.task.id,
            isTaskRule: this.onlyStandard,
          },
          uploadFile: {
            fileId: v.fileId!,
            fileName: v.name,
            fileExtention: v.name.substring(v.name.lastIndexOf('.')),
            fileTaskId: this.task.id,
            fileConvertState: 0,
            fileSize: v.size,
            fileUrl: v.filePath ?? '',
            isLocked: true,
          },
        }))
      const res = await Promise.all(promises)
      if (res.every(v => v && !api.error(v))) {
        this.uploadFileModalVisible = false
        this.fileList = []
        this.$message.success('保存成功！')
        this.fetchTaskFiles(this.task.id)
      }
    },
    async handleRetry(item) {
      const file = this.allFileList!.find(v => v.taskFile?.id === item.id)!
      // 发起转换
      const convertRes: any = await apiJobs.convertModel(
        { fileId: file.uploadFile?.fileId!, tessLevel: this.precision },
        this.$store.state.sessionId,
      )
      // 发起转换成功
      if (convertRes.status.error === 0) {
        this.setFileConvertStatus(file, ConverStatus.inProgress)
        // 轮询转换进度
        this.pollConvertStatus(file)
      }
    },
    handleDeleteTaskFile(id) {
      myConfirm(this, {
        text: '确认删除此文件？',
        resolve: async () => {
          const res = await apiJobs.removeTaskFile({ id })
          if (res && !api.error(res))
            this.fetchTaskFiles(this.task.id)
        },
      })
    },
    async handleDownloadFile(id) {
      const currentFile = this.allFileList.find(v => v.taskFile?.id === id)
      const res: any = await apiJobs.downloadFile(
        { fileId: currentFile?.uploadFile?.fileId! },
        this.$store.state.sessionId,
      )
      const blob = new Blob([res], { type: 'application/octet-stream' })
      const reader = new FileReader()
      reader.onload = function (e) {
        const a = document.createElement('a')
        const fileName = currentFile?.uploadFile?.fileName
        a.download = decodeURIComponent(fileName!)
        a.href = e.target!.result as string
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
      reader.readAsDataURL(blob)
    },
    async fetchStudentCommits(id) {
      const res = await apiJobs.fetchStudentCommits({
        skipCount: 0,
        maxResultCount: 999,
        taskId: this.task.id,
        userId: id,
      })
      if (res && !api.error(res)) {
        this.commitDetailList = res.data.items.map((v) => {
          if (v.uploadFile)
            return { ...v }

          return {
            ...v,
            uploadFile: {
              fileName: '资料',
              fileId: 0,
              id: 0,
            },
          }
        })
      }
      else { this.$message.error(res?.error?.message) }
    },
    getRemark(mark) {
      if (mark < 60)
        return '不及格'

      if (mark < 70)
        return '及格'

      if (mark < 80)
        return '中等'

      if (mark < 90)
        return '良好'

      return '优秀'
    },
    handleCommitStatusHeaderBatchOperation(command) {
      if (this.commitStatusTableSelection.some(v => !v.checkReportInfo)) {
        Message.error('只能选择系统审查完毕的作业')
        return
      }
      switch (command) {
        case 0:
          if (this.commitStatusTableSelection.some(v => v.commitInfo?.status !== 1)) {
            Message.warning('只能选择待批改的作业哦')
            return
          }
          myConfirm(this, {
            text: '确定将系统审查得分同步到学生得分？',
            resolve: async () => {
              const res = await batchSynchronizationScore({
                taskId: this.commitStatusTableSelection[0].commitInfo.taskId,
                taskCommitIds: this.commitStatusTableSelection.map(v => v.commitInfo.id),
              })
              if (res && !api.error(res)) {
                this.$message.success('同步学生得分成功')
                this.fetchCommits()
              }
              else {
                Message.error(res.error.message)
              }
            },
          })
          break
        default:
          myConfirm(this, {
            text: `确定${command === 1 ? '公开' : '私密'}系统审查？`,
            resolve: async () => {
              const res = await batchCommitLocked({
                reportLocked: command !== 1,
                taskCommitIds: this.commitStatusTableSelection.map(v => v.commitInfo.id),
              })
              if (res && !api.error(res)) {
                this.$message.success(`${command === 1 ? '公开' : '私密'}系统审查成功`)
                this.fetchCommits()
              }
              else {
                Message.error(res.error.message)
              }
            },
          })
          break
      }
    },
    async handleCommitCheck(id) {
      this.fetchStudentCommits(id)
      this.commitDetailVisible = true
    },
    async fetchTaskMembers() {
      const res: any = await getMemberList({
        taskId: this.task.id,
        skipCount: 0,
        maxResultCount: 999,
        inTask: true,
      })
      this.memberList = res.data.items
    },
    handleMemberConfigureTableSelect(val) {
      this.memberConfigureTableSelection = val
    },
    refreshMemberList() {
      this.fetchTaskMembers()
    },
    refreshCommit() {
      this.fetchStudentCommits(this.commitDetailList[0]?.userId)
      this.fetchCommits()
    },
    handleCommitStatusSelectionChange(e) {
      this.commitStatusTableSelection = e
    },
    async handleAudit() {
      if (!this.standardFileList.length) {
        this.$message.warning('请先上传标准模型！')
        return
      }
      this.auditLoading = false
      Message.success('请求系统审查成功，请耐心等待系统审查结果。如果系统审查始终未出结果，请联系管理员。')
      await apiJobs.outputAuditModelPdf({
        taskCommitId: this.commitDetailList?.[0]?.commitInfo.id,
        userId: this.commitDetailList?.[0]?.userId,
        isRetry: true,
      })
      // if (res && !api.error(res)) {
      //   this.$message.success("审查成功");
      //   let link = document.createElement("a");
      //   link.href = res.data.errorFileUrl;
      //   link.download = "";
      //   link.target = "_blank";
      //   document.body.appendChild(link);
      //   link.click();
      //   document.body.removeChild(link);
      // } else {
      //   this.$message.error(res?.error?.message);
      // }
      // this.auditLoading = false;
    },
    handleCommitClick(item: Commit) {
      this.commit = item
      this.fileModalVisible = true
      // this.modelViewVisible = true
    },
    handleModleCheck() {
      this.fileModalVisible = false
      this.viewFile = this.commit.uploadFile
      this.modelViewVisible = true
    },
  },
})
