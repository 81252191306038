
import Vue, { PropType } from "vue";
import { Task } from "@/features/collage/types";
import TaskItem from "@/features/collage/task-item/index.vue";
export default Vue.extend({
  props: {
    tasks: { type: Array as PropType<Task[]>, default: [] },
    loading: { type: Boolean }
  },
  data() {
    return { emptyItems: new Array(10) };
  },
  components: { TaskItem }
});
