
import { removeTaskMembers } from "@/api/Jobs";

import Vue from "vue";
import * as api from "@/api";
import { Member } from "../types";
import { myConfirm } from "@/utils/confirm";
export default Vue.extend({
  props: {
    memberList: { type: Array },
    task: { type: Object }
  },
  data() {
    return { multipleSelection: [] };
  },
  methods: {
    handleSelectionChange(val) {
      this.$emit("onSelect", val);
      this.multipleSelection = val;
    },
    async handleDelete(item: Member) {
      myConfirm(this, {
        text: "确认要移除此成员吗？",
        resolve: async () => {
          const res = await removeTaskMembers({
            taskId: this.task.id,
            targetIds: [item.userId]
          });
          if (res && !api.error(res)) {
            this.$emit("refreshMemberList");
          }
        }
      });
    }
  }
});
