
import Vue, { PropType } from 'vue';
import { Commit, Task } from '@/features/collage/types';
import Header from './header.vue';
import Item from './item.vue';

export default Vue.extend({
  components: { Header, Item },
  props: {
    visible: { type: Boolean as PropType<boolean> },
    list: { type: Array as PropType<Commit[]> },
    task: { type: Object as PropType<Task> },
    auditLoading: { type: Boolean, default: false },
  },
  computed: {
    taskName(): string {
      return this.list?.[0]?.commitInfo.taskName || '';
    },
  },
});
