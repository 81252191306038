
import type { PropType } from 'vue'
import Vue from 'vue'
import moment from 'moment'
import type { Commit } from '../types'

enum Status {
  保存 = 0,
  待批改 = 1,
  已驳回 = -1,
  已完成 = 2,
}
export default Vue.extend({
  props: {
    list: { type: Array as PropType<Commit[]> },
  },
  data() {
    return {}
  },
  computed: {},
  created() {
    // console.log(this.item);
  },
  methods: {
    getStatus(val: number) {
      switch (val) {
        case Status.保存:
          return ''
        case Status.待批改:
          return ''
        case Status.已驳回:
          return 'danger'
        case Status.已完成:
          return 'success'
        default:
          break
      }
    },
  },
  filters: {
    formatTime(val) {
      if (val)
        return moment(val).format('YYYY.MM.DD')
    },
    formatStatus(val) {
      return Status[val]
    },
  },
})
